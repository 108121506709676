/**
 * @class SignalRHandler
 */
export class SignalRHandler {
    /**
     * @param {Object} pOptions - Options for configuring the SignalRHandler.
     * @param {(event: any) => void} [pOptions.onMessage] - Callback function to handle messages.
     * @param {boolean} [pOptions.logging] - Flag to enable or disable logging.
     * @param {() => string | string} [pOptions.groupName] - Name of the group.
     * @param {boolean} [pOptions.autoReconnect] - Flag to enable or disable automatic reconnection.
     * @param {Array<string>} [pOptions.targets] - Name of targets that should be listened to in addition to 'SendMessageAsync'
     */
    constructor(pOptions) {
        this.priv = {
            groupName: pOptions?.groupName,
            logging: pOptions?.logging ?? false,
            connection: null,
            defaultCallback : pOptions?.onMessage,
            autoReconnect : pOptions.autoReconnect ?? true,
            callbacks: {},
            targets: pOptions.targets ?? [],
        };
    }

    async connect() {
        try {
            this.#getConnection().on('SendMessageAsync', (type, message) => this.#messageReceived(type, message));

            this.priv.targets.forEach((target) => {
                this.#getConnection().on(target, (type, message) => this.#messageReceived(type, message));
            });

            this.#getConnection().onclose((type, message) => {
                if (type instanceof Error) {
                    this.priv.callbacks['error'](type.message);
                }

                if (message instanceof Error) {
                    this.priv.callbacks['error'](message.message);
                }

                this.priv.callbacks["closed"](message);
            });

            this.#getConnection().onreconnecting((type, message) => {
                this.priv.callbacks["reconnecting"](message);
            });

            this.#getConnection().onreconnected((type, message) => {
                this.priv.callbacks["reconnected"](message);
            });
           
            await this.#getConnection().start();
            
            console.assert(this.#getConnection().state === signalR.HubConnectionState.Connected);
            console.log("SignalR Connected.");
        } catch(reason) {
            console.assert(this.#getConnection().state === signalR.HubConnectionState.Disconnected);
            
            if (typeof(this.priv.callbacks["closed"]) == 'function') {
                this.priv.callbacks["closed"](reason);
            }
            
            console.warn(reason);

            if (this.priv.autoReconnect) {
                setTimeout(() => this.connect(), 2000);
            }
        }       
    }

    async diconnect() {
        try {
            await this.#getConnection().stop();
        } catch (reason) {
            console.assert(this.#getConnection().state === signalR.HubConnectionState.Disconnected);
            
            if (typeof(this.priv.callbacks["closed"]) == 'function') {
                this.priv.callbacks["closed"](e);
            }
            
            console.warn(reason);
        }
    }

    #tryGetJson(message) {
        try {
            if (message && message != null) {
                return JSON.parse(message);
            }

            return message;
        } catch (e) {
            return message;
        }
    }

    #tryGetMessageText(message) {
        try {
            if (typeof(message) == 'object') {
                return JSON.stringify(message);
            }
            
            return message;
        } catch (e) {
            return message;
        }
    }

    #messageReceived(type,message) {
        var msgObj = this.#tryGetJson(message);
        
        if (this.priv.logging) {
            console.log('[' + new Date().toJSON() +'] Debug: Message received: ', type, msgObj);
        }
        
        if (typeof(this.priv.callbacks[type]) == 'function') {
            this.priv.callbacks[type](msgObj);
        } else if (typeof(this.priv.defaultCallback) == "function") {
            this.priv.defaultCallback(type, msgObj);
        } else if (this.priv.logging) {
            console.warn('[' + new Date().toJSON() +'] Debug: No nessage callback registered for type: ', type,);
        } 
    }

    #getConnection() {
        if (this.priv.connection == null) {
            let builder = new signalR.HubConnectionBuilder()
                .withUrl(this.#getGroupName(), {
                    //skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets | 
                        signalR.HttpTransportType.ServerSentEvents | 
                        signalR.HttpTransportType.LongPolling
                })
                .configureLogging(this.priv.logging ? signalR.LogLevel.Debug : signalR.LogLevel.None);
            
            if (this.priv.autoReconnect) {
                builder = builder.withAutomaticReconnect();
            }
               
            this.priv.connection = builder.build();

            this.priv.connection.serverTimeoutInMilliseconds = 1000 * 60 * 5;
        }

        return this.priv.connection;
    }

    #getGroupName(){
        if (typeof(this.priv.groupName) === "function") {
            return this.priv.groupName();
        }

        if (this.priv.groupName == null) {
            this.priv.groupName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        }

        return ("/nt/api/hub/" + this.priv.groupName).toLowerCase();
    }

    sendMessage(type, ...params) {
        try {            
            var connected = this.#getConnection().state == signalR.HubConnectionState.Connected;
 
            if (this.priv.logging) {
                if (connected) {
                    console.log('[' + new Date().toJSON() +'] Debug: Message sending: ', type, ...params);
                } else {
                    console.warn('[' + new Date().toJSON() +'] Debug: Not connected.');
                }
            } else if (!connected) {
                return;
            }
            
            this.#getConnection().invoke('SendMessageAsync', type, ...params.map(p => this.#tryGetMessageText(p)));
        } catch (e) {
            console.log(e);            
        }        
    }

    onMessageReceived(action) {
        this.priv.defaultCallback = action;
    }

    abort() {
        this.#getConnection().abort();
    }

    on(type, listener) {
        return this.priv.callbacks[type] = listener;
    }

    off(type, listener) {
        delete this.priv.callbacks[type];
    }
}
